import React, { useEffect } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth"
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  Timestamp,
} from "firebase/firestore"

import { navigate } from "gatsby"
import AuthLayout from "../../components/AuthLayout"
import { notification, Spin } from "antd"
import styled from "styled-components"

const config = require("../../../config/site")

const SpinWrapper = styled.div`
  margin: 60px auto 30px auto;
  text-align: center;
`

const DynamicPage = () => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  const isBrowser = typeof window !== "undefined"

  const userProcess = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = localStorage.getItem("emailForSignIn")
      const signInObj = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      )
        .then(result => {
          localStorage.removeItem("emailForSignIn")
          return result
        })
        .catch(error => {
          console.log("error signInWithEmailLink ", error)
          notification["error"]({
            message: error.code || error.name || error.status,
            description: error.error_description || error.message,
            duration: 0,
          })
          return null
        })
      if (signInObj && signInObj?.user) {
        // Get users admin
        const uids = []
        const q = query(
          collection(db, "users"),
          where("role", "==", "system_admin")
        )
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data?.uid) {
            uids.push(data.uid)
          }
        })
        const { uid, email, metadata } = signInObj.user
        // Check is user exists
        const existsRef = doc(db, "users", uid)
        const existsSnap = await getDoc(existsRef)
        if (existsSnap.exists()) {
          const dataLog = {
            uid: uid,
            type: "Authentication",
            action: "SignIn",
            by: "System",
            createdAt: Timestamp.now(),
          }
          await addDoc(collection(db, "logs"), dataLog)
          navigate("/backend/dashboard")
        } else {
          const dataUser = {
            uid: uid,
            email: email,
            createdAt: metadata?.creationTime,
          }
          localStorage.setItem("firebaseLocalStorage", JSON.stringify(dataUser))
          const docRef = doc(db, "users", uid)
          await setDoc(docRef, dataUser, { merge: true }).catch(error => {
            console.log("error setDoc ", error)
            notification["error"]({
              message: error.code || error.name || error.status,
              description: error.error_description || error.message,
              duration: 0,
            })
          })
          if (uids && uids.length > 0) {
            // Send notification / email to Admin
            const noticeTitle = `New user (${email}) was registered at Solar e-Doc`
            const dataMail = {
              toUids: uids,
              message: {
                subject: noticeTitle,
                html: `Sign in to use Solar e-Doc Web application, by <a href="${config.siteUrl}/auth/signin" target="_blank"><strong>Click Here</strong></a>`,
              },
            }
            await addDoc(collection(db, "mail"), dataMail)
            // Inbox
            for (let j = 0; j < uids.length; j++) {
              const userId = uids[j]
              if (userId) {
                const dataNotice = {
                  uid: userId,
                  title: noticeTitle,
                  read: false,
                  createdAt: Timestamp.now(),
                }
                await addDoc(collection(db, "notifications"), dataNotice)
              }
            }
            navigate("/backend/dashboard")
          } else {
            navigate("/backend/dashboard")
          }
        }
      }
    }
  }
  useEffect(() => {
    if (isBrowser) {
      userProcess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <AuthLayout>
      <SpinWrapper>
        <Spin size="large" tip="Processing..." />
      </SpinWrapper>
    </AuthLayout>
  )
}

export default DynamicPage
